import DownloadLink from "@components/downloadLink"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { graphql } from "gatsby"
import React from "react"

function TextBooks({ data }) {
  return (
    <Layout>
      <SEO title="Wykaz podręczników - ZSP Nidek" />
      <h1>Wykaz podręczników</h1>
      <DownloadLink data={data} strapiCollection="allStrapiWykazPodrecznikow">
        Wykaz podręczników
      </DownloadLink>
    </Layout>
  )
}

export default TextBooks

export const query = graphql`
  query TextBooksQuery {
    allStrapiWykazPodrecznikow {
      edges {
        node {
          plik_wykaz_podrecznikow {
            ext
            id
            name
            size
            url
          }
        }
      }
    }
  }
`
